// MAIN.JS
jQuery(function($) {

	/**********************************************/
	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {
				// Activer ceci pour faire l'inverse lors que l'element sort de la partie visible
				// $(this).css("opacity", 0);
			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/

	// JQUERY.MASKEDINPUT
    if ($.fn.mask) {
        $("input[type='tel']").mask("(999) 999-9999? #99999");
        $("input[type='text'].postalcode").mask("a9a 9a9", {
            completed:function () {
                this.val(this.val().toUpperCase());
            }
        });
    }

	/**********************************************/
	if ($.fn.owlCarousel) {
		var owlTemoignage = $('.owl-temoignage').owlCarousel({
			loop: true,
			margin: 30,
			autoplay: true,
			autoplayTimeout: 3000,
			center: true,
			slideBy: 1,
			fluidSpeed: 250,
			dots: false,
			dotsEach: true,
			nav: false,
			navText: [''],
			responsive:{
				0:{
			    	items:1
				},
			}
		});
	}

	$('.prev').click(function() {
	    owlTemoignage.trigger('prev.owl.carousel', [300]);
	})
	// Go to the previous item
	$('.next').click(function() {
	    owlTemoignage.trigger('next.owl.carousel');
	})
	
/**********************************************/

	$slideshow = $('.slideshow');
	var initSlideshow = function() {
		return setInterval(function() {
			$slides = $slideshow.find('.slide');
			$active = $slideshow.find('.slide.active');
			$next = $active.next();

			if ($next.length > 0) {
				$active.removeClass('active');
				$active.addClass('transiting');
				$active.next().addClass('active');
			}
			else {
				$active.removeClass('active');
				$active.addClass('transiting');
				$slides.first().addClass('active');
			}
			setTimeout(function() {
				$active.removeClass('transiting');
			}, 1500);
		}, 3000);
	}

	$(document).ready(function() {
		var slideshowTimer = initSlideshow();
	});

	/**********************************************/
	//==== Scroll animation observer
	/**********************************************/

	function scrollAnimation(element, options = {rootMargin: '0px 0px 0px 0px'}) {
		const observer = new IntersectionObserver(watch, options);
	
		const items = element.querySelectorAll('[data-scroll]');
		items.forEach(item => observer.observe(item));
		
		function watch(entries, observer) {
			entries.forEach(entry => {
				const target = entry.target;
				
				if (entry.isIntersecting) {
					target.classList.add('is-active');
					observer.unobserve(target);
					
				} else {
					target.classList.remove('is-active');
				}
			});
		}
	}

	// put .scroll-animation-container on the body of every page that needs to be observed for animations on scroll
	const elements = document.querySelectorAll('.scroll-animation-container');
	elements.forEach(element => scrollAnimation(element));


	/**********************************************/
	/**********************************************/

	// MENU
	var menu = ".menu-mobile";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}


	/************************************************************************/
	/* menu hide and show when scrolled	and other scroll functions			*/
	/************************************************************************/
    let didScroll;
    let lastScrollTop = 0;
    const delta = 10;
    let navbarHeight = $(".menu-navbar").outerHeight();
    // function to store scroll amount in <html data-scroll="">
	const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY;
    }

    // Set flag to see if window has scrolled
	$(window).scroll( function(event) {
		didScroll = true;
        storeScroll();
	});

    // Calls all scroll dependent scripts
    // And cut on processing time by the browser
    // by reading stats only x milliseconds (250)
	setInterval(function() {
		if (didScroll) {
            menuHasScrolled();
            toggleVisibleClassOnScroll();
            didScroll = false;
		}
	}, 250);

	// if element has "visibilityCheck" it will have "isVisible" class when
    // visible
	function toggleVisibleClassOnScroll() {
        $('.visibilityCheck').is( function () {
            let viewing = $(this).visible(true);
            if (viewing) {
                $(this).addClass('isVisible');
            } else {
                $(this).removeClass('isVisible');
            }
        });
        $('.completeVisibilityCheck').is( function () {
            let viewing = $(this).visible();
            if (viewing) {
                $(this).addClass('isCompletelyVisible');
            } else {
                $(this).removeClass('isCompletelyVisible');
            }
        });
    }

    // Adds the nav-invisible and nav-visible to element with class menu-desktop
	function menuHasScrolled() {
        let st = parseInt(document.documentElement.dataset.scroll);

        // Make sure they scroll more than delta
	    if ( Math.abs(lastScrollTop - st) <= delta )
	        return;
	    // If they scrolled down and are past the navbar, add class .nav-invisible.
	    // This is necessary so you never see what is "behind" the navbar.
        //console.log('st: ' + st);
        //console.log('lastScrollTop: ' + lastScrollTop);

        if ( st < parseInt(lastScrollTop) ) {
            // Scroll Up
            $('.menu-navbar').addClass('nav-visible').removeClass('nav-invisible');
        }
	    if (st > lastScrollTop && st > navbarHeight) {
	        // Scroll Down
	        $('.menu-navbar').removeClass('nav-visible').addClass('nav-invisible');
	    }

		lastScrollTop = st;

		if (st > 0) {
			$('.module-menu').addClass('scrolled');
		}
		else {
			$('.module-menu').removeClass('scrolled');
		}
	}

	
    $(document).ready(function () {
        storeScroll();
		toggleVisibleClassOnScroll();
		//code pour cacher les title SEO dans les images
		$("img[title]").hover(function(){
			$(this).attr("rel", $(this).attr("title"));
			$(this).removeAttr("title");
		}, function(){
			$(this).attr("title", $(this).attr("rel"));
			$(this).removeAttr("rel");
		});
    });


	/************************************************/
	/* Ferme le popup si cliquer	 				*/
	/************************************************/
	$(".popup").click(function(e) {
	    $(".popup").fadeOut();
	});


	/************************************************/
	/* Montre les messages si présents 				*/
	/************************************************/
	$(document).ready(function () {
        if($('.errors').length) {
            $('html, body').animate({
                scrollTop: $('.errors').offset().top + 200
            }, 'slow');
        }
    });

    $(document).ready(function () {
		if($('.messages').length) {
			$('html, body').animate({
				scrollTop: $('.messages').offset().top + 200
			}, 'slow');
		}
    });

    $('.caption').addClass ('show');
});
